.form__main {
    background: white;
    max-width: 600px;
    @media only screen and (max-width: 600px) {
        max-width: fit-content;
    }
}

.rightside--container {
    display: flex;
    flex-direction: column;
    // margin: 0px 34px;
    padding: 10px;
    height: 100vh;
    input {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    Button {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        letter-spacing: 2px;
    }
}

.form-container {
    display: flex;
    flex-direction: column;
    // gap: 24px;
    .toggle__buttons {
        display: flex;
        flex-direction: column;
        border: 1px solid darkgrey;
        border-radius: 4px;
        padding: 12px;
        p {
            font-size: 16px;
            padding-bottom: 12px;
        }
        .MuiFab-root {
            border-radius: 8px;
            background-color: white;
            color: black;
            font-size: 12px;
            height: 54px;
            font-weight: 700;
            @media only screen and (max-width: 600px) {
                font-size: 10px;
                justify-content: center;
            }
        }
        .Mui-disabled {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            background-color: #f1f2f3;
            font-weight: 400;
        }
        .active__btn {
            background-color: cornflowerblue;
            color: white;
        }
        .loan__amount {
            display: grid;
            gap: 12px;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
        
            .first {  grid-area: 1 / 1 / 2 / 2; }
            .sec { grid-area: 1 / 2 / 2 / 3; }
            .third { grid-area: 1 / 3 / 2 / 4; }
            .fourth { grid-area: 2 / 1 / 3 / 4; }
        }
        .loan__tenure__emi {
            display: grid;
            gap: 12px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @media only screen and (max-width: 600px) {
                grid-template-columns: 1fr 1fr;
            }
        }
        .Mui-selected {
            color: white;
            background-color: cornflowerblue;
            font-weight: 800;
            font-size: large;
        }

        
    }
}

.form--errorBox {
    padding: 12px;
    text-align: justify;
    background-color: rgba(255, 0, 0, 0.5);
    border: 3px solid #8b0000;
    padding: 20px;
    font-weight: bold;
    border-radius: 25px;
}
.form--successBox {
    padding: 12px;
    text-align: justify;
    background-color: rgba(13, 255, 0, 0.5);
    border: 3px solid #008c05;
    padding: 20px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form--radioGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .label-font {
        font-size: 20px;
        color: #5383f9;
    }
}

.form--radioGroup-box {
    border: 1px solid darkgrey;
    border-radius: 4px;
    padding: 12px;
}

.form--disclaimer {
    font-size: 9px;
    color: #8b0000;
    text-align: center;
}

.form--disclaimer--primary {
    font-size: 12px;
    color: #8b0000;
    font-weight: bold;
    text-align: center;
}

.form--radioGroup--row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .label-font {
        font-size: 20px;
        color: #5383f9;
    }
    
    .form--radioGroup--label
    {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;    
    }
}

.form--radioGroup-box--row {
    border: 1px solid darkgrey;
    border-radius: 4px;
    padding: 12px;
}

.input-uploader-container{
    .input-uploader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px;
    }

    .input-uploader--title{
        font-size: 20px;
        font-weight: 600;
    }
}