
$mobile: only screen and (max-width: 600px);

.page--container {
    background-color: white;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row; 
    overflow: scroll;
    flex-wrap: wrap;
    justify-content: flex-start;
    
}

.auth--page--container {
    justify-content: center;
}