
$mobile: only screen and (max-width: 600px);

.main {
    .content__main{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @media only screen and (max-width: 600px) {
            row-gap: 24px;
        }
        row-gap: 120px;
        color: white;
        .title {
            font-size: 48px;
            font-weight: 700;

            @media only screen and (max-width: 600px) {
                font-size: 30px;
            }
        }
        .title1 {
            color: white;
        }
        .title2 {
            color: #feb404;
        }
        li {
            color: white;
            font-size: larger;
            font-weight: 400;

            @media only screen and (max-width: 600px) {
                font-size: 16px;
            }
        }

        .footer {
            font-size: large;
            @media only screen and (max-width: 600px) {
                font-size: 14px;
            }
        }
        
    }
}
