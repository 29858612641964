.caution-comp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 25px;
    &.caution-comp--success {
      background-color: #b1eb9f;
    border: 3px solid #53c41a;
      .icon {
        color: #53c41a;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    &.caution-comp--info {
      background-color: #9caae2;
    border: 3px solid #1a3cc4;
      .icon {
        color: #1a3cc4;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    &.caution-comp--error {
      background-color: rgba(230, 161, 161, 0.5);
    border: 3px solid #f5212d;
      .icon {
        color: #f5212d;
      }
      .title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .icon {
      font-size: 48px;
    }
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 2px;
      margin-left: 4px;
    }
    .sub-title {
      font-size: 16px;
      text-align: center;
      font-weight: 400;
      line-height: 1.7;
    }
    .title-container{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .extra {
      margin-top: 16px;
    }
  }
  